<template>
  <v-skeleton-loader
    :loading="loadings.app"
    :style="{ height: '100%', width: '100%' }"
    type="card-heading, image@3"
  >
    <v-container
      fluid
    >
      <v-row
        justify="space-between"
        align="baseline"
      >
        <v-col
          cols="auto"
          class="title-m-bold neutral-900--text"
        >
          Товары и услуги
        </v-col>
        <v-col
          cols="auto"          
        >
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <base-text-field
                    v-model.trim="search"
                    class="field-search"
                    placeholder="Быстрый поиск"
                    prepend-inner-icon="$iconify_ion-search-outline"
                    clear-icon="$iconify_ion-close-circle-outline"
                    :prepend-inner-icon-color="this.$vuetify.theme.themes.light['neutral-500']"
                    clearable
                    hide-details
                    @input="getNomenList"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-radio-group
                    v-model="searchInCategory"
                    class="ma-0"
                    row
                    @change="getNomenList"
                  >
                    <v-radio
                      label="В выбранной категории"
                      :value="true"
                    />
                    <v-radio
                      label="По всем категориям"
                      :value="false"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="secondary"
                :to="{name:'NomenclatureImportMaster'}"
              >
                <v-icon left>
                  $iconify_clarity-import-line
                </v-icon>
                Импорт
              </v-btn>
              <v-btn
                @click="clickExport"
                class="ml-1"
                color="secondary"
                v-if="userIsAdmin"
              > 
              <v-icon left>
                  $iconify_clarity-export-line
                </v-icon>
                Экспорт
              </v-btn>
              <v-btn
                class="btn-create-dialog"
                color="primary"
                @click="clickDialogCreate"
              >
                <v-icon
                  class="icon"
                  color="neutral-100"
                  size="21"
                  style="margin-right: 8px;"
                >
                  $iconify_feather-plus-circle
                </v-icon>
                <p class="body-m-semibold neutral-100--text mb-0">
                  Создать
                </p>
              </v-btn>
              <v-menu v-if="userPermissionsSet.has('program-nomenclature-delete-all') || authUser.is_super_admin" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab color="primary" elevation="2" class="ml-1" v-bind="attrs" v-on="on">
                    <v-icon small> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="clickDeleteAllNomenclature">
                    <v-list-item-title>Удалить все</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          class="pa-0"
          cols="12"
        >
          <div class="category-and-table">
            <div class="left-block">
              <v-btn 
                text 
                small 
                color="secondary" 
                class="mb-1"
                @click="clickCategoryCreate"
              >
                <v-icon small secondary>$iconify_feather-plus</v-icon>Добавить категорию
              </v-btn>
              <extended-category
                ref="extendedCategory"
                class="categories"
                :items="nomenCatTree"
                child-key="childs"
                item-value="uuid"
                item-text="name"
                @clickItem="clickedCategoryItem"
                @editOpen="clickCategoryEdit($event)"
              />
            </div>
            <div class="right-block">
              <base-table
                ref="table"
                class="table"
                :headers="tableHeaders"
                :options.sync="tableOptions"
                :items="tableItems"
                :show-expand="false"
                :loading="loadings.table"
                :server-items-length="nomenTotalCount"
                :word-operations="['позиция', 'позиции', 'позиций']"
              >
                <template v-slot:[`item.name`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        v-bind="attrs"
                        class="item-name body-s-medium mb-0"
                        v-on="on"
                      >
                        {{ item.name }}
                      </p>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.segments`]="{ item }">
                  <segment-chip
                    v-for="segment in item.segments"
                    :key="segment.id"
                    :name="segment.name"
                    :color="segment.color"
                    :max-len="40"
                    style="margin: 5px;"
                    chip
                  />
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <div class="item__actions">
                    <v-btn
                      class="btn-action-edit"
                      color="neutral-600"
                      text
                      :loading="loadings.editItemUuid == item.uuid"
                      :disabled="Boolean(loadings.editItemUuid.length) && loadings.editItemUuid != item.uuid"
                      @click="clickDialogEdit(item)"
                    >
                      <v-icon
                        class="icon neutral-600--text"
                        size="21"
                      >
                        $iconify_feather-edit
                      </v-icon>
                    </v-btn>
                    <v-btn
                      class="btn-action-delete"
                      text
                      color="neutral-600"
                      :loading="loadings.deleteItems[item.uuid]"
                      @click="clickDelete(item)"
                    >
                      <v-icon
                        class="icon neutral-600--text"
                        size="21"
                      >
                        $iconify_feather-trash
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </base-table>
            </div>
          </div>
        </v-col>
      </v-row>
      <side-panel-nomenclature
        v-model="dialog.show"
        :mode="dialog.mode"
        :data="form"
        :get-nomen-list="getNomenList"
      />
      <side-panel-category
        v-model="categoryDialog.show"
        :mode="categoryDialog.mode"
        :data="categoryForm"
        :allCategories="categoryList"
        :programId="programId"
      />
    </v-container>
  </v-skeleton-loader>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { deepClone } from '@/utils'
  // components
  import SidePanelNomenclature from './components/SidePanelNomenclature'
  import SidePanelCategory from './components/SidePanelCategory.vue'
  import SegmentChip from '@/components/segment/SegmentChip'
  import Permission from "@/mixins/permission";

  export default {
    components: {
      SidePanelNomenclature,
      SidePanelCategory,
      SegmentChip,
    },
    mixins: [Permission],
    props: {
      pSearch: { type: String, default: undefined },
      pSearchInCategory: { type: Boolean, default: true },
    },
    data () {
      return {
        tableHeaders: [
          { text: 'Наименование', value: 'name', sortable: false },
          { text: 'Цена продажи', value: 'sale_price_format', sortable: false },
          { text: 'UUID', value: 'uuid', sortable: false, width: '9em' },
          { text: 'Сегменты', align: 'end', value: 'segments', sortable: false },
          { text: 'Действия', value: 'actions', align: 'end', sortable: false },
        ],
        tableOptions: {
          page: 0,
          itemsPerPage: 25,
        },
        currCatUuid: null,
        form: {
          name: '',
          description: '',
          category_uuid: '',
          type: '',
        },
        categoryForm:{
          name : '',
          parentCategory : '',
          childs : [],
          id : ''
        },
        dialog: {
          mode: 'create',
          show: false,
        },
        categoryDialog:{
          mode: 'create',
          show: false,
        },
        search: null,
        searchInCategory: true,
        loadings: {
          app: false,
          table: false,
          deleteItems: {},
          editItemUuid: '',
        },
      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
        nomen: 'company/nomen/nomenclature',
        nomenCatTree: 'company/nomen/categoryTree',
        categoryList: 'company/nomen/categoryList',
        nomenList: 'company/nomen/nomenclatures',
        nomenTotalCount: 'company/nomen/nomenTotalCount',
        defaultCategory: 'company/nomen/defaultCategory',
      }),
      theme () {
        return this.$vuetify.theme.themes.light
      },
      tableItems () {
        return this.nomenList.map(item => {
          return Object.assign(item, {
            sale_price_format: (item.sale_price / 100).toLocaleString(),
          })
        })
      },
      userIsAdmin() {
        if (this.userRoles) {
          return this.userRoles.some(item => item.name === 'merchant-admin' || item.name === 'merchant-owner');
        } else {
          return false
        }
      }
    },
    watch: {
      async programId (v) {
        await this.fetch()
      },
      // searchInCategory () {
      //   this.getNomenList()
      // },
      // search (v) {
      //   this.getNomenList()
      // },
      async 'tableOptions.page' () {
        await this.getNomenList()
      },
      async 'tableOptions.itemsPerPage' () {
        await this.getNomenList()
      },
      currCatUuid (v) {
        this.$store.commit('company/nomen/currCategoryUuid', v)
      },
    },
    async mounted () {
      console.log('this.authUser')
      console.log(this.authUser)
      if (this.pSearch) {
        this.search = this.pSearch
        this.searchInCategory = false
      }
      await this.fetch()
    },
    methods: {
      ...mapActions({
        fetchNomenclature: 'company/nomen/getNomenclature',
        fetchNomenList: 'company/nomen/getNomenclatureList',
        fetchNomenCatTree: 'company/nomen/getCategoryTree',
        fetchNomenCatList: 'company/nomen/getCategoryList',
        deleteNomen: 'company/nomen/delete',
        exportNomenclature: 'company/nomenclature/exportNomneclatureFields',
        deleteAllNomenclatureAndCategory: 'company/nomen/deleteAllNomenclatureAndCategory',
        
      }),
      async fetch () {
        this.loadings.app = true
        await this.fetchNomenCatTree()
        await this.fetchNomenCatList()
        setTimeout(() => {
          this.$refs.extendedCategory.$refs.list.clickedItem(this.defaultCategory)
        }, 0)
        this.loadings.app = false
      },
      async clickedCategoryItem (item) {
        if (this.currCatUuid === item.uuid) return

        this.currCatUuid = (item.uuid === this.defaultCategory.uuid)
          ? this.currCatUuid = null
          : this.currCatUuid = item.uuid

        if (this.tableOptions.page !== 1) {
          this.tableOptions.page = 1
          // this.$set(this.tableOptions, 'page', 1)
        } else {
          await this.getNomenList()
        }
      },
      async getNomenList () {
        try {
          this.loadings.table = true
          const searchData = {
            offset: this.getOffset(this.tableOptions.page, this.tableOptions.itemsPerPage),
            search: this.search,
            limit: this.tableOptions.itemsPerPage,
          }
          if (this.searchInCategory || !this.search) {
            searchData.categoryUuid = this.currCatUuid
          }
          console.log('getNomenList', searchData, this.searchInCategory)
          await this.fetchNomenList(searchData)
        } catch (error) {
          console.log(error)
        } finally {
          this.loadings.table = false
        }
      },
      async clickDeleteAllNomenclature() {
        try {
          await this.$alert(
              "Подтвердите полное удаление всей номенклатуры и категорий",
              this.$t('Удаление всей номенклатуры и категорий'),
              {
                confirmButtonText: "Ок",
                type: 'warning',
              },
          )
          await this.deleteAllNomenclatureAndCategory({ programId: this.programId })
        } catch {
        }
      },
      async clickDialogCreate () {
        this.form.nomenclature_uuid = ''
        this.form.name = ''
        this.form.type = 'product'
        this.form.segments = []
        this.form.description = ''
        this.form.category_uuid = this.defaultCategory.uuid
        this.form.external_id = ''
        this.form.external_code = ''
        this.form.sale_price = null //добавлено
        this.dialog.mode = 'create'
        this.dialog.show = true
      },
      async clickDialogEdit (item) {
        this.loadings.editItemUuid = item.uuid
        await this.fetchNomenclature({ uuid: item.uuid })
        this.loadings.editItemUuid = ''
        this.form.nomenclature_uuid = this.nomen.uuid
        this.form.name = this.nomen.name
        this.form.type = this.nomen.type
        this.form.segments = this.nomen.segments
        this.form.description = this.nomen.description
        this.form.category_uuid = this.nomen.category_uuid || this.defaultCategory.uuid
        this.form.external_id = this.nomen.external_id
        this.form.external_code = this.nomen.external_code
        this.form.sale_price = this.nomen.sale_price / 100 //добавлено
        this.$set(this, 'form', deepClone(this.form))
        this.dialog.mode = 'edit'
        this.dialog.show = true
      },
      async clickDelete (item) {
        this.$set(this.loadings.deleteItems, item.uuid, true)
        await this.deleteNomen({ uuid: item.uuid })
        this.$set(this.loadings.deleteItems, item.uuid, false)
      },
      async clickExport () {
        await this.exportNomenclature({
          programId: this.programId,
          email: this.authUser.email
        });
      },
      clickCategoryCreate(){
        console.log(this.categoryForm)
        this.categoryForm.id = ''
        this.categoryForm.name = ''
        this.categoryForm.childs = []
        this.categoryForm.parentCategory = ''
        this.categoryDialog.mode = 'create'
        this.categoryDialog.show = true
      },
      clickCategoryEdit(e){
        console.log(e)
        this.categoryForm.id = e.uuid
        this.categoryForm.name = e.name
        this.categoryForm.childs = e.childs.slice(0)
        this.categoryForm.parentCategory = e.parent_category_uuid ? e.parent_category_uuid : ''
        this.categoryDialog.mode = 'edit'
        this.categoryDialog.show = true
      },
      getOffset (page, limit) {
        const offset = (page * limit) - limit
        return offset >= 0 ? offset : 0
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.sass";

.fields-search.v-text-field.theme--light {
  margin-top: 20px!important;
}

.btn-create-dialog {
  margin-left: 14px;
}

.item__actions {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  .btn-action-edit, .btn-action-delete {
    min-width: 0;
    width: 35px;
    height: 35px!important;
    border-radius: 50%;
    align-self: center;
  }
}

.category-and-table {
  display: flex;
  margin-top: 34px;
  margin-bottom: 34px;
  max-width: 100%;
  @media screen and (max-width: 1131px) {
    max-width: calc(100% - 252px);
  }
  .left-block {
    min-width: 252px;
    margin-right: 50px;
    margin-top: 34px;

    .v-btn.theme--light.v-size--small{
      padding: 12px 0px !important;
    }

    .v-btn.v-btn--text.theme--light.secondary--text:hover {
      color: $primary-base !important
    }

    .visible {
      visibility: visible;
    }
    .hidden{
      visibility: hidden;
    }

    .categories {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 715px;
      max-width: 252px;
      padding-bottom: 1px;
      @include scroll;
      & ::v-deep {
        .category__list {
          padding-right: 12px;
          .category__item {
              .item-text {
                &.clicked {
                  .left-block {
                    p {
                      pointer-events: none;
                      color: $primary-base !important;
                    }
                    
                  }
                }
              }
            .left-block {
              &.open {
                p {
                  color: $neutral-600!important;
                }
              }
            }
          }
        }
      }
    }
  }
  .right-block {
    width: 100%;
    .table {
      width: 100%;
      .btn-checkbox {
        width: 25px;
        height: 25px;
        min-width: 0;
        border-radius: 50%;
      }
      .item-name {
        display: inline-block;
        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      & ::v-deep {
        tbody {
          tr {
            height: 45px!important;
          }
        }
      }
    }
  }
}

</style>
