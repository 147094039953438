<template>
  <base-drawer-dialog
    :value="show"
    :width="580"
    :title="mode === 'create' ? 'Создание номенклатуры' : 'Редактирование номенклатуры'"
    @change="$emit('change', $event)"
  >
    <v-form
      ref="formnomen"
      v-model="validForm"
      class="form"
    >
      <BaseDialogFieldBlock title="ID" v-if="mode !== 'create'">
        <base-text-field
          v-model="form.nomenclature_uuid"
          class="field-name"
          placeholder="ID"
          maxlength="255"
          readonly
        />
      </BaseDialogFieldBlock>
      <BaseDialogFieldBlock title="Наименование">
        <base-text-field
          v-model="form.name"
          class="field-name"
          placeholder="Наимоневание"
          maxlength="255"
          :rules="rules.name"
          validation-placement="top"
        />
      </BaseDialogFieldBlock>
      <BaseDialogFieldBlock title="Описание">
        <base-text-area
          v-model="form.description"
          class="field-description"
          placeholder="Описание"          
          :rules="rules.description"
          maxlength="8192"
          counter="8192"
        />
        <!-- Добавлена цена-->
        <BaseDialogFieldBlock title="Цена продажи">
          <base-text-field
              v-model="form.sale_price"
              class="field-name"
              placeholder="Цена продажи"
              type="number"
              min="0"
              :rules="rules.sale_price"
              validation-placement="top"
          />
        </BaseDialogFieldBlock>
        <!-------------------->
      </BaseDialogFieldBlock>
      <BaseDialogFieldBlock title="Внешний ID">
        <base-text-field
          v-model="form.external_id"
          class="field-name"
          placeholder="Внешний код"
          maxlength="255"
        />
      </BaseDialogFieldBlock>
      <BaseDialogFieldBlock title="Внешний код">
        <base-text-field
          v-model="form.external_code"
          class="field-name"
          placeholder="Внешний код"
          maxlength="255"
        />
      </BaseDialogFieldBlock>
      <BaseDialogFieldBlock title="Категория">
        <v-autocomplete
          v-model="form.category_uuid"
          :items="categoryList"
          :rules="rules.category"
          :search-input.sync="search.category"
          placeholder="Выберите категорию"
          hide-selected
          item-value="uuid"
          item-text="name"
          outlined
          clearable
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Ничего на найдено!
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{item}">
            <segment-chip
              :name="item.name"
              :color="theme.primary"
              chip
            />
          </template>
          <template v-slot:item="{item}">
            <segment-chip
              :name="item.name"
              max-width="380px"
            />
          </template>
        </v-autocomplete>
      </BaseDialogFieldBlock>
      <BaseDialogFieldBlock title="Тип">
        <v-autocomplete
          v-model="form.type"
          class="field-type"
          :items="nomenTypesList"
          :rules="rules.type"
          :search-input.sync="search.type"
          placeholder="Выберите тип"
          hide-selected
          outlined
          clearable
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Ничего на найдено!
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{item}">
            <segment-chip
              :name="item.text"
              :color="theme.primary"
              chip
            />
          </template>
          <template v-slot:item="{item}">
            <segment-chip
              :name="item.text"
              max-width="380px"
            />
          </template>
        </v-autocomplete>
      </BaseDialogFieldBlock>
      <BaseDialogFieldBlock title="Сегменты">
        <v-row>
          <v-col cols="12">
            <div v-if="countAutoSegment > 0" class="mb-2">
              <SegmentAutocomplete
              v-model="dataSegments"
              :items="segmentsAuto"
              :clearable="false"                           
              label="Автоматические"
              readonly
              multiple
              />
            </div>
            <div>
              <SegmentAutocomplete
              v-model="dataSegments"
              :items="segmentsManual"
              label="Ручные"
              multiple
              />
            </div>
          </v-col>
        </v-row>
      </BaseDialogFieldBlock>
      <v-expansion-panels>
        <v-expansion-panel class="expasion-panel_fields">
          <v-expansion-panel-header>
            <p class="body-l-semibold mb-0">
              Дополнительные поля
            </p>
            <template v-slot:actions>
              <v-icon color="neutral-700">
                $iconify_feather-chevron-down
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <nomenclature-fields
              v-if="nomenFields && nomenFields.length"
              ref="nomenfields"
              :data.sync="nomenFieldsData"
              :loading-acc-fields.sync="loadingNomenAccFields"
              :valid-form.sync="validNomenForm"
              :uuid="mode === 'edit' ? data.nomenclature_uuid : ''"
              :not-filled="mode === 'create'"
            />
            <v-btn
              v-else
              class="btn btn-setting align-self-center"
              color="primary"
              @click="$router.push({ name: 'SettingsCRM' })"
            >
              <iconify-icon
                icon="feather-settings"
                width="21"
                style="margin-right: 8px"
              />
              <p
                class="body-m-semibold mb-0"
              >
                Настроить
              </p>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
    <v-btn
      color="primary"
      class="btn-create"
      :disabled="!validForm || (mode === 'create' && nomenFields.length > 0 && !validNomenForm)"
      :loading="loadingNomen"
      @click="mode === 'edit' ? fetchUpdateNomen() : fetchCreateNomen()"
    >
      <iconify-icon
        class="icon"
        icon="ion-checkmark-circle-outline"
        width="21"
        style="margin-right: 8px;"
      />
      <p class="body-m-semibold mb-0 neutral-100--text">
        {{ mode === 'edit' ? 'Сохранить' : 'Создать' }}
      </p>
    </v-btn>
  </base-drawer-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { trimLR, deepClone } from '@/utils'
  import { maxLen } from '@/utils/validate'
  import SegmentAutocomplete from '@/components/segment/SegmentAutocomplete.vue'

  export default {
    components: {
      SegmentChip: () => import('@/components/segment/SegmentChip'),
      NomenclatureFields: () => import('@/components/nomenclature/Fields.vue'),
      SegmentAutocomplete
    },
    model: {
      prop: 'show',
      event: 'change',
    },
    props: {
      show: Boolean,
      mode: {
        type: String,
        default: 'create',
      },
      data: {
        type: Object,
        default: () => {
          return {}
        },
      },
      getNomenList: {
        type: Function,
        default: function () {},
      },
    },
    data () {
      const defaultCategory = this.$store.getters['company/nomen/defaultCategory']

      return {
        form: {
          name: '',
          description: '',
          category_uuid: defaultCategory.uuid,
          type: 'product',
          segments: [],
          //добавлен sale_price
          sale_price: null,
          //
        },
        rules: {
          name: [
            v => !!v || 'Обязательно для заполенения',
            v => maxLen(trimLR(v), 255) || 'Не более 255 символов',
          ],
          description: [
            v => !v || maxLen(trimLR(v), 8192) || 'Не более 8192 символов',
          ],
          category: [
            v => !!v || 'Обязательно для заполенения',
          ],
          type: [
            v => !!v || 'Обязательно для заполенения',
          ],
          // добавлено правило для цены
          sale_price: [
            v => v !== null || 'Обязательно для заполенения',
            v => v >= 0 || 'Цена не может быть отрицательной',
          ],
          //
        },
        langNomenTypes: {
          product: 'Продукт',
          service: 'Сервис',
          bundle: 'Комплект',
          variant: 'Модификация',
          consignment: 'Партия',
        },
        loadingNomen: false,
        search: {
          category: '',
          type: '',
        },
        segmentToAdd: null,
        validForm: false,
        validNomenForm: true,
        nomenFieldsData: {},
        nomenOrigFieldsData: {},
        loadingNomenAccFields: false,
      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
        categoryList: 'company/nomen/categoryList',
        defaultCategory: 'company/nomen/defaultCategory',
        nomenList: 'company/nomen/nomenclatures',
        nomenTotalCount: 'company/nomen/nomenTotalCount',
        segmentPickList: 'company/nomen_segment/pickList',
        nomenclatureFields: 'settings/nomenclature-fields/fields',
      }),
      nomenFields () {
        return this.nomenclatureFields[this.programId]
      },
      nomenTypesList () {
        return Object.entries(this.langNomenTypes).map(([key, val]) => {
          return { text: val, value: key }
        })
      },
      theme () {
        return this.$vuetify.theme.themes.light
      },
      dataSegments: {
        get: function () {
          return this.data.segments
        },
        set: function (newValue) {
          const valueSegmentMaual = newValue.filter(item => {
            return item.type === 'manual'
          })

          const valueSegmentAuto = this.data.segments.filter(item => {
            return item.type === 'auto'
          })

          this.data.segments = valueSegmentMaual.concat(valueSegmentAuto)          
        }
      },
      segmentsManual () {
        return this.segmentPickList.filter(item => {
          return item.type === 'manual'
        })
      },
      segmentsAuto () {
        return this.segmentPickList.filter(item => {
          return item.type === 'auto'
        })
      },
      countAutoSegment() {
        if (this.data.segments) {
          return this.data.segments.filter(item => {
            return item.type === 'auto'
          }).length
        }
      },
      preparedNomenFields () {
        const prepared = []

        Object.keys(this.nomenFieldsData).forEach(key => {
          if (typeof this.nomenFieldsData[key] === 'object' && this.nomenFieldsData[key]?.value) {
            prepared.push({ id: key, value: this.nomenFieldsData[key].value })
          } else {
            prepared.push({ id: key, value: this.nomenFieldsData[key] })
          }
        })

        return prepared
      },
      hasChangesInFields () {
        return JSON.stringify(this.nomenFieldsData) !== JSON.stringify(this.nomenOrigFieldsData)
      },
    },
    watch: {
      show (v) {
        if (this.mode === 'create' && v) {
          this.$refs.formnomen && this.$refs.formnomen.resetValidation()
          this.$refs.nomenfields &&
            this.$refs.nomenfields.$refs.formFields.resetValidation()
        }
        if (!v) {
          this.data.nomenclature_uuid = ''
        }
      },
      data: {
        deep: true,
        handler (v) {
          if (this.mode === 'edit') {
            this.form = v
          }
        },
      },
      loadingNomenAccFields (v) {
        if (!v) {
          this.nomenOrigFieldsData = deepClone(this.nomenFieldsData)
        }
      },
    },
    async mounted () {
      await this.fetchSegmentPickList({ programId: this.programId })
      await this.getFields({ programId: this.programId, withStub: true })
    },
    methods: {
      ...mapActions({
        createNomen: 'company/nomen/create',
        updateNomen: 'company/nomen/update',
        fetchSegmentPickList: 'company/nomen_segment/getPickList',
        getFields: 'settings/nomenclature-fields/GetFields',
        updateNomenFields: 'company/nomen_fields/UpdateFields',
      }),
      async fetchCreateNomen () {
        if (this.$refs.formnomen.validate() &&
          (!this.$refs.nomenfields || this.$refs.nomenfields.$refs.formFields.validate())) {
          this.loadingNomen = true

          const data = deepClone(this.form)
          if (data.category_uuid === this.defaultCategory.uuid) data.category_uuid = null
          this.form.segments = this.data.segments
          data.segments = this.form.segments.map(s => s.id)

          data.sale_price = parseFloat(data.sale_price) * 100;

          // create
          await this.createNomen(data)
            .then(async res => {
              if (this.preparedNomenFields.length > 0) {
                await this.updateNomenFields({
                  nomenclature_uuid: res.uuid,
                  fields: this.preparedNomenFields,
                }).then(async res => {
                  this.$emit('change', false)
                })
              } 
            })
            .finally(async () => {
              this.loadingNomen = false
              await this.getNomenList()
            })
        } else {
          this.$notify({
            type: 'error',
            title: 'Создание номенклатуры',
            text: 'Ошибки валидации',
          })
        }
      },
      async fetchUpdateNomen () {
        if (this.$refs.formnomen.validate() && (this.nomenFields.length === 0 || this.validNomenForm)) {
          this.loadingNomen = true

          const data = deepClone(this.form)

          if (data.category_uuid === this.defaultCategory.uuid) data.category_uuid = null
          const dataCompare = this.nomenList.find(n => n.uuid === data.nomenclature_uuid)
          data.segments = this.form.segments.filter(s => s.type === 'manual').map(s => s.id)

          data.sale_price = parseFloat(data.sale_price) * 100;

          await this.updateNomen(data)
            .then(async (res) => {
              if (res.category_uuid !== dataCompare.category_uuid) {
                this.$store.commit('company/nomen/deleteInNomen', res.uuid)
                this.$store.commit('company/nomen/nomenTotalCount', this.nomenTotalCount - 1)
              } else {
                this.getNomenList()
              }

              console.log('hasChangesInFields', this.hasChangesInFields)

              if (this.hasChangesInFields && this.preparedNomenFields.length > 0) {
                const data = {
                  nomenclature_uuid: res.uuid,
                  fields: this.preparedNomenFields,
                }
                await this.updateNomenFields(data)
              }
              this.$emit('change', false)
            })
            .finally(() => {
              this.loadingNomen = false
            })
        } else {
          this.$notify({
            type: 'error',
            title: 'Сохранение номенклатуры',
            text: 'Ошибки валидации',
          })
        }
      },
      removeSegment (index) {
        this.form.segments.splice(index, 1)
        this.$set(this.form, 'segments', this.form.segments)
      },
    },
  }
</script>

<style lang="scss" scoped>

.btn-create {
  margin-top: 34px;
}

.form {
  margin-top: 24px;
  .field-name {
    margin-top: 8px;
  }
  .field-name, .field-description {
    margin-bottom: 34px;
  }
  .field-category {
    margin-bottom: 4px;
  }
  .field-type {
    margin-bottom: 8px;
    & ::v-deep {
      .v-text-field__details {
        margin-bottom: 0!important;
      }
    }
  }
}

.expasion-panel_fields {
  margin-top: 45px;
}
</style>
