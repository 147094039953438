<template>
    <base-drawer-dialog
      :value="show"
      :width="580"
      :title="mode === 'create' ? 'Создание категории' : 'Редактирование категории'"
      @change="$emit('change', $event)"
    >
      <v-form
        ref="formcat"
        v-model="validForm"
        class="form"
      >
        <BaseDialogFieldBlock title="ID" v-if="mode !== 'create'">
            <base-text-field
                v-model="form.id"
                class="field-name"
                placeholder="ID"
                maxlength="255"
                readonly
            />
        </BaseDialogFieldBlock>
        <BaseDialogFieldBlock title="Название">
            <base-text-field
                v-model="form.name"
                class="field-name"
                placeholder="Название категории"
                maxlength="255"
            />
        </BaseDialogFieldBlock>
        <BaseDialogFieldBlock 
          v-if="mode === 'create' || (mode !== 'create' && form.parentCategory.length)"
          title="Родительская категория"
          >
            <v-autocomplete
                v-model="form.parentCategory"
                :items="categories"
                item-text="name"
                item-value="uuid"
                class="field-name"
                placeholder="Выберите категорию"
                :readonly="mode === 'edit'"
                outlined
                :clearable="mode === 'create'"
            />
        </BaseDialogFieldBlock>
        <BaseDialogFieldBlock title="Дочерние категории"
          v-if="mode === 'edit' && form.childs.length"
        >
            <v-autocomplete
              v-if="level < 10"
              :items="categories"
              item-text="name"
              item-value="uuid"
              v-model="form.childs"
              placeholder="Дочерние категории"
              class="field-name"
              outlined
              multiple
              readonly
            >

            </v-autocomplete>
            
        </BaseDialogFieldBlock>
      </v-form>
      <div class="d-flex flex-row justify-space-between align-center btn-create">
        <v-btn
          color="primary"
          :disabled="this.form.name.length < 3"
          :loading="loading"
          @click="saveCategory"
        >
          <iconify-icon
            class="icon"
            icon="ion-checkmark-circle-outline"
            width="21"
            style="margin-right: 8px;"
          />
          <p class="body-m-semibold mb-0 neutral-100--text">
            {{ mode === 'edit' ? 'Сохранить' : 'Создать' }}
          </p>
        </v-btn>
        <v-btn
          v-if="mode === 'edit'"
          text
          :loading="loading"
          class="error--text"
          @click="clickDeleteCategory"
        >
          <iconify-icon
            class = "icon"
            icon="feather-trash"
            width="16"
            style="margin-right: 8px;"
          />
          <p class="body-m-semibold mb-0">
            Удалить
          </p>

        </v-btn>
      </div>
      
    </base-drawer-dialog>
  </template>
  
  <script>
import { mapActions } from 'vuex'
    export default {
      model: {
        prop: 'show',
        event: 'change',
      },
      props: {
        show: Boolean,
        mode: {
          type: String,
          default: 'create',
        },
        data: {
          type: Object,
          default: () => {
            return {}
          },
        },
        allCategories :{
          type : Array,
          default : null
        },
        programId : Number,
      },
      data () {
        return {
            validForm : false,
            level : 0,
            loading : false,
            form :{
                name : '',
                parentCategory : '',
                childs : [],
                id : ''
            }
        }
      },
      computed: {
        categories(){
          let selectCategories = this.allCategories.slice(1)
          //selectCategories.splice(selectCategories.indexOf(selectCategories.find((el, idx) => {return el.uuid === this.form.id})), 1)

          // let parentId = this.form.parentCategory
          // while(parentId){
          //   let parentElement = selectCategories.find((el, idx) => {return el.uuid === parentId}) 
          //   parentId = selectCategories.find((el, idx) => {return el.uuid === parentElement.uuid}).parent_category_uuid
          //   selectCategories.splice(selectCategories.indexOf(parentElement), 1)
          // }
          return selectCategories
        }
      },
      watch: {
        data: {
        deep: true,
        handler (v) {
          if (this.mode === 'edit') {
            this.form = v
            this.getNestingLevel()
          }
          if (this.mode === 'create'){
            this.form = {
                name : '',
                parentCategory : '',
                childs : [],
                id : ''
            }
          }
        },
      },
      },
      mounted () {
      },
      methods: {
        ...mapActions({
          createCategory : 'company/nomen/createCategory',
          updateCategory : 'company/nomen/updateCategory',
          deleteCategory : 'company/nomen/deleteCategory',
        }),
        getNestingLevel(){
          this.level = 0
          let parentId = this.form.parentCategory
          console.log("PARENT ID", parentId)
          while(parentId){
            let parentElement = this.allCategories.find((el, idx) => {return el.uuid === parentId}) 
            console.log("PARENT ELEMENT", parentElement);
            if (parentElement)
              this.level ++
            parentId = this.allCategories.find((el, idx) => {return el.uuid === parentElement.uuid}).parent_category_uuid
          }
        },
        async saveCategory(){
          this.loading = true
          let error = null
          if (this.mode === 'create'){
            let data = {
              program_id : this.programId,
              name : this.form.name,
              parent_uuid : this.form.parentCategory ? this.form.parentCategory : null,
            }
            try{
              await this.createCategory(data)
            }
            catch(err){
              error = err
            }
          }
          if (this.mode === 'edit'){
            let data = {
              program_id : this.programId,
              name : this.form.name,
              uuid : this.form.id
            }

            try{
              await this.updateCategory(data)
            }
            catch(err){
              error = err
            }
          }
          if (!error)
            this.$emit('change', false)
          this.loading = false
        },
        async clickDeleteCategory(){
          this.loading = true

          await this.deleteCategory(this.form.id)
          this.$emit('change', false)
          this.loading = false
        }
      },
    }
  </script>
  
  <style lang="scss" scoped>
  
  .btn-create {
    margin-top: 34px;
  }
  
  .form {
    margin-top: 24px;
    .field-name {
      margin-top: 8px;
    }
    .field-name, .field-description {
      margin-bottom: 34px;
    }
    .field-category {
      margin-bottom: 4px;
    }
    .field-type {
      margin-bottom: 8px;
      & ::v-deep {
        .v-text-field__details {
          margin-bottom: 0!important;
        }
      }
    }
  }
  
  .expasion-panel_fields {
    margin-top: 45px;
  }
  </style>
  